@import '../../variables/variables';

.CostingCalculatorContainer {
  padding: 30px !important;
}

.CostingCalculator {
  display: none;

  @media (min-width: $breakpoint-s) {
    display: block;
  }
}

.WarningMessage {
  display: block;

  @media (min-width: $breakpoint-s) {
    display: none;
  }
}

.TableHeader {
  color: #979797;
  font-size: 0.95rem;
  text-align: center;
}

.TableHeader .LeftAligned {
  text-align: left;
}

.ButtonsContainer {
  position: static !important;
  padding-top: 30px;

  .ButtonWrapper {
    max-width: 95%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.DarkGreen,
    .Button.Orange {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 1.18rem;
      width: 48%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }

    .Button.Green,
    .Button.Green:link,
    .Button.Green:visited,
    .Button.Green:active {
      background-color: #FFFFFF !important;
      border: 1px solid #74C791 !important;
      color: #006666 !important;
    }

    .Button.Green:hover {
      background-color: #006666 !important;
      border-color: #006666 !important;
      color: #FFFFFF !important;
    }

    .Button.Green:disabled {
      background-color: #FFFFFF !important;
    }
  }
}

.Purchase.Price.Input.Text {
  max-width: 110px !important;
  border-radius: 80px 0px 0px 80px !important;
}

.CostingCalculatorIngredient {
  align-self: center !important;
  padding: 0 !important;
  color: #333333;
  height: 50px;
}

.Separator {
  display: inline-block;
  height: 3px;
  width: 100%;
  border-top: 3px #EAEAEA dashed;
}

.FixedPadding {
  padding-top: 10px !important;
}

.CurrencyField,
.PurchaseUnitField {
  position: absolute !important;
  top: 9px !important;
  right: 10px !important;
  max-width: 45px !important;
  font-size: 14px !important;
  color: #333333 !important;
  background-color: #E5E5E5 !important;
}

.BatchItem {
  background: #F2F2F2;
  color: #333333;
  margin: 0 12px;
}

.Button.Basic.Modal,
.Button.Orange.Modal {
  font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  font-size: 1.18rem;
  width: 48%;

  @media (min-width: $breakpoint-xs) {
    font-size: 1.28571429rem;
    width: 100%;
  }
}

.Button.Basic.Modal {
  color: #F2711C !important;
}

.Modal.Description {
  font-size: 18px;
  padding: 12px 22px;
}

.Modal.Container {
  padding: 0;
}

.Text {
  font-size: 1.1rem;

  @media (min-width: $breakpoint-s) {
    font-size: 1.2rem;
  }

  span {
    font-size: 1.1rem;
    padding-left: 15px;
  }

  & + .Subtitle {
    color: #828282;
    font-size: 0.825rem;

    @media (min-width: $breakpoint-s) {
      font-size: 0.9rem;
    }
  }
}

div.UI.Red.Label {
  background-color: #F9DBDA !important;
  color: #333333 !important;
  display: flex; 
  justify-content: space-between;
  text-align: center;
  font-size: 13px !important;
  padding: 6px 0 6px 12px;
  border-radius: 8px;

  i::before {
    background-color: #FFFFFF !important;
    border-radius: 50% !important;
  }
}

.Popup {
  color: #828282 !important;
  background: #E5E5E5 !important;
  font-size: 13px !important;
  min-width: 490px !important;
  padding: 6px 10px !important;

  &::before {
    background: #E5E5E5 !important;
  }
}

.ChangeSignOrder {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.PurchasePrice {
  position: relative;

  span {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px 18px;
    background-color: #E5E5E5;
    border-radius: 0 80px 80px 0;
    border: 1px solid #BDBDBD;

    @media (min-width: $breakpoint-l) {
      padding-left: 28px;
      padding-right: 28px;
    }
    @media (min-width: $breakpoint-xl) {
      padding-left: 34px;
      padding-right: 34px;
    }
  }
}