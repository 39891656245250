@import '../variables/variables';

.SplashScreen {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;

  .BackgroundLogo {
    position: absolute;
    bottom: -150px;
    right: -150px;
    z-index: 0;
    min-width: 500px;
    width: 500px;
    -webkit-animation:spin 10s linear infinite;
    -moz-animation:spin 10s linear infinite;
    animation:spin 10s linear infinite;

    @media (min-width: $breakpoint-s) {
      min-width: 800px;
      width: 800px;
      bottom: -200px;
      right: -200px;
    }
  }
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.Branding {
  width: 100%;
  position: fixed;
  top: 20%;
  z-index: 4;
}

.Slogan {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  text-align: center;

  @media (min-width: 767px) {
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
  }
}

.Logo img {
  width: 450px;
  height: auto;
  display: block;
  margin: 0 auto;
}
