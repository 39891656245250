@import '../../variables/variables';

.OrderFormContainer {
  margin-top: 20px;
  margin-bottom: 70px;

  @media (min-width: $breakpoint-s) {
    margin-bottom: 100px;
  }
}

.OrderSuggestions {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9fafb;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  @media (max-width: 599px) {
    padding: 20px;
  }
}
.SuggestionsToggle {
  display: flex;
  justify-content: center;
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin: 0 auto;
  }
}
.SuggestionsMessage {
  font-style: italic;
  color: #6e6e6e;
  margin-bottom: 1rem !important;
  @media (max-width: 599px) {
    margin-top: 20px;
  }
}

.OrderDays {
  margin-bottom: 1rem !important;
  font-size: 16px;
  line-height: 2.5;

  p {
    margin-bottom: 5px;
  }

  > div > div {
    //Grid column
    padding: 0;
  }
}

.DateRange input {
  width: 300px !important;
  max-width: 300px !important;
}

.DeliveryDate {
  margin: 15px 0 20px !important;
  font-size: 16px;

  @media (max-width: 769px) {
    > div > div {
      text-align: center !important;
    }

    div div:nth-child(2) > div {
      margin-top: -1rem !important;
    }
  }
}

.OrderFormTable {
  thead tr > th:last-child {
    line-height: 1;
  }

  tbody td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    > label {
      display: none;
    }
  }

  /* Resizes the checkbox */
  tbody td:last-child {
    > div > div {
      label:before,
      label:after {
        width: 24px;
        height: 24px;
      }

      label:after {
        font-size: 24px;
        top: 3px;
      }
    }
  }

  @media (max-width: 767px) {
    tbody {
      tr {
        display: block;
        border-bottom: 1px solid #ccc;

        > td {
          border: 0 !important;

          label {
            display: block;
            font-size: 0.8em;
            color: #9f9f9f !important;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 5px;
          }

          &:not(:last-child) {
            display: inline-block !important;
          }

          &:last-child {
            width: 20% !important;

            > div > div {
              label:after {
                top: 0px;
              }
            }
          }

          &:nth-child(2n) {
            width: 80% !important;

            p {
              display: inline-block;
              margin-right: 10px !important;
            }
          }

          &:nth-child(3n),
          &:nth-child(4n),
          &:nth-child(5n) {
            font-size: 16px;
            width: 33% !important;
          }

          &:nth-child(3n) {
            padding-right: 0 !important;
          }

          &:nth-child(5n) {
            text-align: right !important;
            padding-left: 0 !important;
          }

          &:last-child {
            display: block !important;
            width: 100% !important;
            padding: 10px 20px 10px 20px !important;
          }
        }
      }
    }
  }
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper {
    max-width: 760px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.DarkGreen,
    .Button.Orange {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 1.18rem;
      width: 48%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }

    .Button.Green,
    .Button.Green:link,
    .Button.Green:visited,
    .Button.Green:active {
      background-color: #FFFFFF !important;
      border: 1px solid #74C791 !important;
      color: #006666 !important;
    }

    .Button.Green:hover {
      background-color: #006666 !important;
      border-color: #006666 !important;
      color: #FFFFFF !important;
    }

    .Button.Green:disabled {
      background-color: #FFFFFF !important;
    }    
  }

  .TwoButtonsContainer {
    display: none;
    width: 0px;
    justify-content: space-between;

    @media (min-width: $breakpoint-s) {
      display: flex;
      width: 430px;
    }
  }

  .Ui.Actions.Dropdown.Order.Form {
    display: block;
    min-width: 41px;
    width: 45% !important;
    text-align: center;
    font-size: 16px;
    border: 1px solid #74C791 !important;
    color: #006666 !important;

    @media (min-width: $breakpoint-s) {
      width: 35% !important;
      font-size: 1.28571429rem;
    }

    @media (min-width: $breakpoint-s) {
      display: none !important;
    }

    &[aria-expanded=true] {
      border-radius: 20px !important;
    }

    & [role=listbox] {
      font-size: 16px;
      border-radius: 0;
      border-bottom-width: 1px !important;

      @media (min-width: $breakpoint-s) {
        font-size: 1.28571429rem;
      }
    }
  }
}