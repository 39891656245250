@import '../../variables/variables';

.ShoppingListDetails {
  max-width: 580px;
  margin: 14px auto 30px auto;
  position: relative;
  
  @media (min-width: $breakpoint-xs) {
    margin: 36px auto 30px auto;
  }

  div[class*=grid-headers], div[class*=grid-item] {
    justify-content: space-around !important;

    div[class*=column]:nth-child(2) {
      display: none;

      @media (min-width: $breakpoint-xs) {
        display: inline;
      }
    }

    div[class*=column]:nth-child(1), div[class*=column]:nth-child(3), div[class*=column]:nth-child(4) {
      width: 30% !important;

      @media (min-width: $breakpoint-xs) {
        width: 25% !important;
      }
    }

    div[class*=column]:nth-child(2) {
      width: 40% !important;

      @media (min-width: $breakpoint-xs) {
        width: 31.25% !important;
      }
    }

    div[class*=column]:nth-child(3) {
      width: 40% !important;

      @media (min-width: $breakpoint-xs) {
        width: 18.75% !important;
      }
    }
  }

  div[class*=grid-headers] {
    border: 1px solid #BDBDBD;
    background-color: #FAFAFA;
    margin-top: 50px;

    @media (min-width: $breakpoint-xs) {
      margin-top: 0;
    }
  }

  div[class*=message-col] {
    padding: 0 !important;
  }

  div[class*=grid-item] {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
    background-color: #F5F5F5;

    @media (min-width: $breakpoint-xs) {
      border-bottom: 1px solid #BDBDBD;
    }
  }

  div[class*=no-padding-bottom] {
    padding-bottom: 0 !important;
  }

  div[class*=mobile-item-number] {
    justify-content: center !important;
    align-items: center !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 36px;
    border: 1px solid #BDBDBD;
    background-color: #FAFAFA;
    margin-bottom: 0;

    p {
      height: fit-content;
    }

    @media (min-width: $breakpoint-xs) {
      display: none !important;
    }
  }

  div[class*=checkbox-row] {
    justify-content: space-between !important;

    div[class*=column] {
      width: 50% !important;

      @media (min-width: $breakpoint-xs) {
        width: 40% !important;
        justify-content: space-between !important;
      }
    }
  }

  div[class*=inputs-grid] div[class*=grid-custom-column] {
    padding: 0 !important;
  }

  div[class*=inputs-grid] div[class*=grid-custom-column-padding-left] {
    padding: 0 0 0 15px !important;
  }

  div[class*=row]:not(.checkbox-row) {
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    @media (min-width: $breakpoint-xs) {
      margin-left: 2.25rem;
      margin-right: 2.25rem;
    }
  }

  .BorderBottom {
    border-bottom: 1px solid #BDBDBD;
  }
}

.ShoppingListDetails > div {
  background-color: #fafafa;
  border-radius: 20px;
  margin: 0 !important;
  padding: 20px 0px !important;
}

.ShoppingListDetails hr {
  border: 0;
  border-top: 2px solid #efefef;
}

.Divider {
  border-top: 3px dashed #efefef !important;
  margin: 0 20px 0;
}

.ShoppingListDetails h2 {
  font-weight: normal;
  font-size: 22px;
  margin-top: 50px !important;

  @media (min-width: $breakpoint-xs) {
    margin-top: 25px !important;
  }
}

.strikethrough {
  text-decoration: line-through;
}