.LoadingContainer {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 600px;
    padding-top: 20px;
 
    p {
       font-size: 22px;
       width: 300px;
       text-align: center;
    }
 }
 
 .CalculatorLogo {
    position: absolute;
    top: 180px; 
 }
 
 .CalculatorLogo.Calculator {
    animation: tilt-shaking 0.4s ease-out infinite;
 }

 @keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}