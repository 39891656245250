@import '../../variables/variables';

.ShoppingListViewDetails {
  position: relative;
  padding-bottom: 70px;
}

.StatusIcon {
  display: block;
  width: 54px;
  height: 54px;
  position: absolute;
  border-radius: 50%;
  top: 14px;
  left: 50%;
  margin: -25px 0 0 -25px;
  text-align: center;

  img {
    max-width: 100%;
    display: inline-block;
  }

  i {
    font-size: 44px !important;
    margin-right: 0 !important;
  }

  @media (min-width: $breakpoint-xs) {
    width: 90px;
    height: 90px;
    margin: -45px 0 0 -45px;
    top: 7px;
  }
}

.ShoppingListViewHeading {
  max-width: 580px;
  background-color: #FAFAFA;
  margin: 36px auto 30px auto;
  text-align: center;
  padding: 60px 0 20px;
  border-radius: 24px;

  p {
    color: #979797;
  }
}

.ShoppingListViewSignature {
  max-width: 580px;
  background-color: #FAFAFA;
  margin: 36px auto 30px auto;
  padding: 30px 40px;
  border-radius: 24px;
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapperCentered {
    max-width: 700px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .Button.Green,
    .Button.Orange {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 1.18rem;
      width: 48%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}