@import '../../variables/variables';

.AccordionContainer {
  padding: 0 !important;
  color: #828282;

  .AccordionTitle {
    width: 100%;
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid #828282;
    font-size: 1.1rem;

    i.Icon {
      color: #828282;
      text-align: right;
    }

    strong {
      color: #333333;
    }
  }

  p.Accordion.Item {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    text-align: left;
    color: #333333;
    padding: 3px 0;

    .Trigger {
      width: 100%;
      text-align: right;
    }
  }

  p.Accordion.Item:first-of-type {
    padding-top: 10px;
  }
}

.Popup {
  background: #E5E5E5 !important;
  color: #828282 !important;
  min-width: 200px !important;

  @media (min-width: $breakpoint-xxs) {
    min-width: 280px !important;
  }

  @media (min-width: $breakpoint-xs) {
    min-width: 400px !important;
  }

  @media (min-width: $breakpoint-s) {
    min-width: 600px !important;
  }

  &::before {
    background: #E5E5E5 !important;
  }
}