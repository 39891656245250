@import '../variables/variables';

.Home {
  text-align: center;
}

.Greeting {
  font-size: 1.3em;
  color: #4db894;
}

.TaskList {
  margin-top: 30px !important;

  @media (min-width: $breakpoint-s) {
    margin-left: 1.5rem !important;
  }
}

.Task {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fefefe;
  position: relative;
  border: 1px solid #efefef;
  padding: 10px 20px 20px 20px;
  border-radius: 20px;
  font-size: 1.3rem;
  min-height: 132px;

  .TaskIconImg {
    display: inline-block;
  }

  .TaskIconImgHover {
    display: none;
  }

  &.Done strong,
  &.Done a {
    color: #63c1a1;
  }
}

.Task:hover {
  cursor: pointer;

  .TaskIconImg {
    display: none;
  }

  .TaskIconImgHover {
    display: inline-block;
  }

  &.Done strong,
  &.Done a {
    color: #26a658;
  }
}

.TaskIcon img {
  width: auto;
  height: 45px;
  max-width: 45px;
  display: block;
  margin: 0 auto;
}

.TaskContainer,
.VerticalMobileLine {
  padding: 0 !important;
}

.VerticalMobileLine {
  hr {
    display: none;
  }

  @media (min-width: 768px) {
    align-self: center;
    hr {
      display: block;
      border: 0;
      border-top: 1px solid #ccc;
    }
  }
}

.VL {
  @media (max-width: 767px) {
    border-left: 2px solid green;
    height: 200%;
    position: absolute;
    left: 50%;
    margin: -1rem 0 -1rem -1px;
    top: 0;
  }
}

.Button.RequestDemo.Help {
  margin: 35px 0 20px !important;
  font-size: 1.2rem;
  background-color: #26A658 !important;
  color: #FFF;

  &:hover {
    color: #FFF;
  }

  @media (min-width: 768px) {
    margin: 0 !important;
    position: absolute;
    right: 50px;
    bottom: 60px;
  }
}

.Desktop {
  top: 5% !important;
  max-width: 1600px !important;

  p {
    margin: 12px 0 20px !important;
    padding-left: 8px !important;
    text-align: center !important;
    font-family: BananaGroteskSemibold, 'Assistant', sans-serif !important;
  }

  button {
    font-size: 28px;
    top: 4px;
    right: 8px;
    font-weight: 700;
  }
}

.Webmobile {
  inset: 5% 5% auto !important;
  max-width: 90% !important;

  p {
    margin: 20px 0 !important;
    padding-left: 8px !important;
    text-align: center !important;
    font-family: BananaGroteskSemibold, 'Assistant', sans-serif !important;
  }

  button {
    font-size: 28px;
    top: 4px;
    right: 8px;
    font-weight: 700;
  }
}

.OptionButtonContainer {
  text-align: center;
  padding: 4px 0;

  .button {
    width: 100%;
  }
}

.Hide {
  display: none !important;
}

.CloseButton {
  position: absolute;
  top: -6px;
  right: 28px;
  font-size: 22px;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: $breakpoint-xs) {
    font-size: 24px;
    top: 0;
    right: 20px;
  }
}