@import '../../variables/variables';

.DistributorsTitle {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 60px;
}

tr td:first-child {
  background-color: transparent !important;
}

.OrderFormTable {
  thead tr > th:first-child {
    line-height: 1;
  }

  tbody td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    > label {
      display: none;
    }
  }

  /* Resizes the checkbox */
  tbody td:first-child {
    > div > div {
      label:before,
      label:after {
        width: 24px;
        height: 24px;
      }

      label:after {
        font-size: 24px;
        top: 3px;
      }
    }
  }

  @media (max-width: 767px) {
    tbody {
      tr {
        display: block;
        border-bottom: 1px solid #ccc;

        > td {
          border: 0 !important;

          label {
            display: block;
            font-size: 0.8em;
            color: #9f9f9f !important;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 5px;
          }

          &:not(:last-child) {
            display: inline-block !important;
          }

          &:first-child {
            width: 20% !important;

            > div > div {
              label:after {
                top: 0px;
              }
            }
          }

          &:nth-child(2n) {
            width: 80% !important;

            p {
              display: inline-block;
              margin-right: 10px !important;
            }
          }

          &:nth-child(3n),
          &:nth-child(4n),
          &:nth-child(5n) {
            font-size: 16px;
            width: 33% !important;
          }

          &:nth-child(3n) {
            padding-right: 0 !important;
          }

          &:nth-child(5n) {
            text-align: right !important;
            padding-left: 0 !important;
          }

          &:last-child {
            display: block !important;
            width: 100% !important;
            padding: 10px 20px 10px 20px !important;
          }
        }
      }
    }
  }
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.Orange {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 1.18rem;
      width: 48%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}