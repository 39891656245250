@import '../../variables/variables';

.FormContainer {
  padding: 20px 20px 100px;
  vertical-align: middle;

  .Amount {
    vertical-align: text-top !important;
  }

  .Trash {
    vertical-align: bottom !important;
    padding: 4px 16px;
  }

  .RecipeName {
    font-size: 22px;
  }

  .Ingredients {
    color: #666;
  }

  .DishesTitle {
    font-size: 28px;
    color: #999;
  }
}

.Title {
  text-align: center;
  padding: 20px 0;
  font-size: 20px;
  width: 320px;
  margin: 0 auto;

  @media (min-width: $breakpoint-s) {
    width: 400px;
  }
}

.ListRow {
  border-bottom: 1px solid #DDD;
}

.InputRow {
  padding: 0 !important;

  @media (min-width: $breakpoint-s) {
    padding: 0 1rem !important;
  }
}

.ButtonsContainer {
  position: fixed !important;
  
  .ButtonWrapper,
  .ButtonWrapperCentered {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button,
    .Button.Green {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 1.17rem;
      min-width: 48% !important;
      max-width: 48% !important;
      width: 48%;
      
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }

    .Button {
      width: 48%;

      @media (min-width: $breakpoint-xs) {
        min-width: 30% !important;
        width: 30% !important;
      }
    }
  }
  
  .ButtonWrapperCentered {
    justify-content: center;
  }
}

.DesktopButton {
  padding-left: 14px;
}

.Text {
  color: #333;
  font-size: 18px;
  width: 260px;
  text-align: center;
  margin: 0 auto;

  @media (min-width: $breakpoint-s) {
    font-size: 24px;
    width: 440px;
  }
}