@import '../../variables/variables';

.Controls {
  padding: 0 0 12px !important;

  > i:before {
    font-size: 1em;
    position: absolute;
    top: -8px;

    @media (min-width: $breakpoint-s) {
      position: static;
      padding: 0 12px;
    }
  }
}

.ItemType label {
  font-size: 1.2em !important;
}

.Subtitle,
.NoSubrecipes {
  color: #979797;
  font-size: 1.1em;
}

.DropDown {
  max-width: 100% !important;
}

.RemoveButton {
  padding: 0 !important;
  background: none !important;
  display: inline-flex !important;

  i {
    font-size: 24px !important;
  }
  span {
    display: none;
  }

  @media (max-width: 669px) {
    i {
      font-size: 1.4em !important;
    }
    span {
      display: inline-block;
      font-size: 1.2em !important;
      padding-left: 5px;
    }
  }
}

.ui.grid.row.Hide {
  display: none;
}

.four.wide .ui.selection.dropdown {
  min-width: 100%;
  min-height: 43px;
  font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  padding: 0.67857143em 1em;
  font-size: 1.2em;
}

.four.wide .ui.selection.dropdown.error {
  background-color: #fff6f6;
  color: #9f3a38;
  border: 1px solid #e0b4b4;
}

.CustomGrid {
  max-width: 98% !important;
  margin: 0 !important;
  padding-bottom: 75px !important;

  @media (min-width: $breakpoint-xs) {
    padding-bottom: 150px !important;
  }


  div[class*=column] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.MobileTitle {
  color: #3C3C43;
  border-bottom: 1px solid #3C3C43;
  padding: 0 !important;
  margin: 1rem 0 !important;
  font-size: 17px;

  @media (min-width: $breakpoint-xs) {
    display: none !important;
  }
}

.HideMobile {
  display: none !important;

  @media (min-width: $breakpoint-xs) {
    display: inline-block !important;
  }
}

.LocationDropdown {

  div[class*=field] {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;

    @media (min-width: $breakpoint-xs) {
      align-items: flex-end;
    }
  }

  button {
    width: 100% !important;
    max-width: 288px !important;

    @media (min-width: $breakpoint-xs) {
      width: 190px !important;
    }
  }
  
}

.RadioInputsContainer {
  display: flex;
  flex-direction: column !important;
  gap: 20px !important;
  
  div[class*=column] {
    width: 100% !important;

    @media (min-width: $breakpoint-xs) {
      width: 25% !important;
    }
  }

  div[class*=column]:not(:nth-child(1)) {
    margin-left: 20px;
    
    label {
      font-size: 14px !important;
      padding-left: 30px;

      @media (min-width: $breakpoint-xs) {
        font-size: 1.2em !important;
      }
    }
  }

  @media (min-width: $breakpoint-xs) {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    width: 25% !important;
  }
}

.MobileCheckbox {
  width: 100% !important;

  p {
    color: #3C3C43;
    font-size: 17px;
    margin-bottom: 1.5px !important;

    @media (min-width: $breakpoint-xs) {
      display: none;
    }
  }

  div[class*=mobile-servings-input] {
    margin-bottom: 1rem;

    @media (min-width: $breakpoint-xs) {
      display: none;
    }
  }

  @media (min-width: $breakpoint-xs) {
    width: 60% !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
}

.DesktopServingsInput {
  display: none !important;

  @media (min-width: $breakpoint-xs) {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
  }
}

.IngredientsSection {
  flex-direction: column-reverse !important;

  @media (min-width: $breakpoint-xs) {
    flex-direction: row !important;
    justify-content: space-between;
  }
}

.CustomInputsRow {
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  gap: 20px;

  > div[class=column] {
    width: 100% !important;
  }

  div[class*=mobile-icon] {
    @media (min-width: $breakpoint-xs) {
      display: none !important;
    }
  }

  div[class*=desktop-icon] {
    display: none !important;

    @media (min-width: $breakpoint-xs) {
      display: inline-block !important;
    }
  }

  @media (min-width: $breakpoint-xs) {
    flex-direction: row !important;
  }
}

.CustomRow {
  align-items: center !important;
  justify-content: space-between !important;
}

.FirstCol {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  width: 100% !important;

  @media (min-width: $breakpoint-xs) {
    width: 43.75% !important;
    gap: 0px;
  }
}

.SecondCol {
  width: 100% !important;

  @media (min-width: $breakpoint-xs) {
    width: 25% !important;
  }
}

.EditComboContainer {
  padding-bottom: 80px;

  @media (min-width: $breakpoint-s) {
    padding-bottom: 120px;
  }
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper {
    max-width: 670px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.Orange {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 1.18rem;
      width: 48%;

      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}

.Modal-Header {
  font-size: 18px !important;
}

.Modal-Description {
  font-size: 16px !important;
}

.Confirm-Button {
  font-size: 18px !important;
  margin: 0 auto !important;
  &,
  &:link,
  &:visited,
  &:active {
      color: #006666 !important;
      background-color: #FFFFFF !important;
      border: 2px solid #006666 !important;
  }
  &:hover {
      color: #FFFFFF !important;
      background-color: #004d4d !important;
      border: 2px solid #004d4d !important;
  }
}

.Row.SubMenuItem {
  justify-content: space-between !important;

  .Servings {
    padding-left: 0 !important;

    @media (min-width: $breakpoint-s) {
      padding-left: 24px !important;
    }
  }
  
  @media (min-width: $breakpoint-s) {
    align-items: baseline !important;
  }
}

.Modal-Header {
  font-size: 18px !important;
}

.Modal-Description {
  font-size: 16px !important;
}

.Confirm-Button {
  font-size: 18px !important;
  margin: 0 auto !important;
  &,
  &:link,
  &:visited,
  &:active {
      color: #006666 !important;
      background-color: #FFFFFF !important;
      border: 2px solid #006666 !important;
  }
  &:hover {
      color: #FFFFFF !important;
      background-color: #004d4d !important;
      border: 2px solid #004d4d !important;
  }
}

div.Modal.Actions button {
  margin-bottom: 14px !important;

  @media (min-width: 767px) {
    margin-bottom: 0 !important;
  }
}

.AllergensTitle {
  color: #828282;
  font-size: 1.1rem;
  border-bottom: 1px solid #828282;

  &.NoBorder {
    border-bottom: none;
  }
}

.AllergensDescription {
  color: #828282;
  font-size: 1rem;
  padding: 8px 0;
}

.PillsContainer {
  position: relative;
  padding: 18px 30px 18px 8px;

  .Pill {
    display: inline-block;
    background: #f2f2f2;
    padding: 4px 6px;
    border-radius: 8px;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  .AllergensTrigger {
    position: absolute;
    top: 18px;
    right: 10px;
    font-size: 16px;
  }
}

.AllergensPopup {
  background: #E5E5E5 !important;
  color: #828282 !important;
  min-width: 200px !important;

  @media (min-width: $breakpoint-xxs) {
    min-width: 280px !important;
  }

  @media (min-width: $breakpoint-xs) {
    min-width: 400px !important;
  }

  @media (min-width: $breakpoint-s) {
    min-width: 600px !important;
  }

  &::before {
    background: #E5E5E5 !important;
  }
}