@import '../../variables/variables';

.LinkContainer {
  border: 1px solid #DDD;
  display: flex;
  justify-content: space-between;
  padding: 14px 28px;
  font-size: 16px;
  font-weight: 300;
  margin: 30px 0;
  border-radius: 50px;
  box-shadow: 0px 0px 5px 2px #ccc;
  color: #333;

  @media (min-width: $breakpoint-s) {
    font-size: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  & + & {
    margin-top: 50px;
  }

  &.Disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.ChevronRight {
  color: #89EDAC;
}