@import '../variables/variables';

.SwitchLanguageButtonsContainer {
  position: absolute;
  top: 25px;
  left: 5px;
  z-index: 4;

  span {
    font-family: BananaGroteskRegular;
    padding: 8px 10px;
    font-size: 14px;
    border-left: 2px solid #FFF;
    cursor: pointer;
  }

  span:first-child {
    border-left: none;
  }

  @media (min-width: $breakpoint-xs) {
    left: 20px;
  }

  @media (min-width: $breakpoint-s) {
    top: 35px;
    left: 100px;

    span {
      padding: 10px 15px;
      font-size: 18px;
    }
  }
}

.SelectedLocation {
  color: #204D36;
  background-color: #58DC99;
  font-size: 12px;
  padding: 95px 15px 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (min-width: $breakpoint-s) {
    font-size: 14px;
    padding: 115px 20% 15px;
  }
}

.SelectedLocationMain {
  padding-top: 400px;
}

.Toaster {
  font-size: 10px !important;
  position: fixed !important;
  top: 45px;
  left: 25px;
  background: #FFFFFF !important;
  padding: 22px 60px 22px 16px !important;
  z-index: 4 !important;
  color: #006666 !important;

  @media (min-width: $breakpoint-xs) {
    bottom: 120px;
    right: 30px;
    top: auto;
    left: auto;
    font-size: 1em !important;
    padding: 22px 66px 22px 20px !important;
  }

  a,
  a:link,
  a:visited,
  a:active {
    color: #FFFFFF;
    background-color: #006666;
    border: 1px solid #006666;
    padding: 4px 8px;
    border-radius: 20px;
    margin-left: 12px;

    @media (min-width: $breakpoint-xs) {
      padding: 8px 16px;
    }
  }

  a:hover {
    color: #006666;
    border: 1px solid #006666;
    background-color: #FFFFFF;
  }

  a:before {
    content: '';
    display: inline-block;
    position: absolute;
    right: 46px;
    top: 13px;
    height: 34px;
    width: 2px;
    background-color: #006666;

    @media (min-width: $breakpoint-xs) {
      height: 40px;
    }
  }

  i[class*=close] {
    color: #006666 !important;
    opacity: 1 !important;
    top: 21px !important;
    right: 15px !important;
    font-size: 18px;

    @media (min-width: $breakpoint-xs) {
      font-size: 16px;
    }
  }
}