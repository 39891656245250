@import '../../variables/variables';

.StockList {
  margin-top: 30px;
  margin-bottom: 130px;
}

.StockList thead th {
  font-weight: normal !important;
}

.TopInputsGrid {
  margin: 0 !important;

  .TopInputsRow {
    display: flex !important;
    flex-direction: column-reverse !important;
    gap: 20px;

    .TopInputsColumn {
      width: 100% !important;

      @media (min-width: $breakpoint-xs) {
        width: 50% !important;
      }
    }

    @media (min-width: $breakpoint-xs) {
      flex-direction: row !important;
      gap: 0px;
    }
  }
}

.ButtonsContainer {
  position: fixed !important;
}

.ButtonWrapper,
.ButtonWrapperCentered {
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  .Button.Green {
    font-family: BananaGroteskRegular, 'Assistant', sans-serif;
    font-size: 1.18rem;
    width: 48%;

    @media (min-width: $breakpoint-xs) {
      font-size: 1.28571429rem;
      width: 100%;

      &:not(.stock-trigger-button) {
        max-width: 230px;
      }
    }
  }
}

.ButtonWrapperCentered {
  justify-content: center;
}

table {
  font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
  color: #333333;
  font-weight: 300;

  @media (min-width: $breakpoint-s) {
    font-size: 20px;
  }
}

td, th {
  text-align: left;
  padding: 12px 8px;
}

tr:nth-child(odd) {
  background-color: #F9FAFB;
  ;
}

th.Center,
td.Center {
  text-align: center;
}

th.Right,
td.Right {
  text-align: right;
}

.SmallerFontSize {
  font-size: 11px;

  @media (min-width: $breakpoint-s) {
    font-size: 14px;
  }
}

.IngredientSearch {
  text-align: center;
}

.IngredientSearch input {
  width: 200px !important;
  font-size: 15px !important;

  @media (min-width: $breakpoint-xs) {
    width: 350px !important;
    font-size: 17px !important;
  }
  
  @media (min-width: $breakpoint-s) {
    width: 500px !important;
  }
  
  color: #FF795E !important;

  &::placeholder {
    color: #FF795E !important;
  }
}

.Items,
.ArchivedCheckbox label {
  font-size: 15px !important;
  color: #BDBDBD !important;

  @media (min-width: $breakpoint-s) {
    font-size: 20px !important;
  }
}

.StockAuditsButtonContainer {
  padding: 20px 0 35px;
  text-align: center;

  button {
    max-width: 400px !important;
  }
}

