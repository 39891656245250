@import '../../variables/colors';
@import '../../variables/variables';

.Recipe {
  min-height: 155px;
}

.Title {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  width: max-content;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 220px;
  text-align: center;

  @media (min-width: $breakpoint-xs) {
    max-width: 410px;
  }
}

.Detail {
  padding: 15px 20px;
}

.Detail p {
  margin-bottom: 10px;
}

.BtnContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.RecipeDetail {
  max-width: 580px;
  margin: 0 auto !important;
}

.RecipeDetail > div {
  max-width: 80%;
  margin: 0 auto !important;
}

.RecipeDetail .ButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 36px;
  margin-top: 24px !important;

  @media (min-width: $breakpoint-s) {
    justify-content: space-between;
  }

  .Button.Green {
    font-size: 1.14285714rem;
    font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  }

  .Button.Green.CostingButton {
    display: none;
    
    @media (min-width: $breakpoint-s) {
      display: inline-block;
    }
  }
}

.RecipeDetail .Button.Green.ViewCombo {
  font-size: 1.14285714rem;
  font-family: BananaGroteskRegular, 'Assistant', sans-serif;
}

.RecipeDetail .ButtonsContainerCentered {
  display: flex;
  justify-content: center;
  gap: 36px;
  margin-top: 24px !important;
  
  .Button.Green {
    font-size: 1.14285714rem;
    font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  }
}

.Heading {
  font-size: 14px;
  color: #9a9a9a;
}

.RecipeDetail hr {
  border: 0;
  border-top: 3px dashed #efefef;
}

.Archived {
  background-color: #dfdfdf;
}

.Locations {
  font-size: 0.8em;
}

.LocationLabel {
  color: $grey-light;
  font-size: 13px;
}

.NoteItem {
  margin: 6px 0 2px;

  & + & {
    margin-top: 0;
  }
}

.BatchItem {
  display: flex;
  justify-content: space-between;
  background: #F2F2F2;
  color: #333333;
  padding: 15px;
  margin: 15px 10px 10px !important;
}

.Modal-Header {
  font-size: 18px !important;
}

.Modal-Description {
  font-size: 16px !important;
}

.Confirm-Button {
  font-size: 18px !important;
  margin: 0 auto !important;
  &,
  &:link,
  &:visited,
  &:active {
      color: #006666 !important;
      background-color: #FFFFFF !important;
      border: 2px solid #006666 !important;
  }
  &:hover {
      color: #FFFFFF !important;
      background-color: #004d4d !important;
      border: 2px solid #004d4d !important;
  }
}

.AllergensTitle {
  color: #828282;
  font-size: 1.1rem;
  border-bottom: 1px solid #828282;
}

.PillsContainer {
  position: relative;
  padding: 8px 20px 0 0;

  .Pill {
    display: inline-block;
    background: #f2f2f2;
    padding: 4px 6px;
    border-radius: 8px;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  .AllergensTrigger {
    position: absolute;
    top: 6px;
    right: 4px;
    font-size: 15px;
  }
}

.AllergensPopup {
  background: #E5E5E5 !important;
  color: #828282 !important;
  min-width: 200px !important;

  @media (min-width: $breakpoint-xxs) {
    min-width: 280px !important;
  }

  @media (min-width: $breakpoint-xs) {
    min-width: 400px !important;
  }

  @media (min-width: $breakpoint-s) {
    min-width: 600px !important;
  }

  &::before {
    background: #E5E5E5 !important;
  }
}