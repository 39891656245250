@import '../../variables/variables';

.MobileButton {
    max-width: 100% !important;
    font-size: 16px !important;
    
    @media (min-width: $breakpoint-xs) {
        display: none !important;
    }
}

.DesktopButton {
    display: none !important;

    @media (min-width: $breakpoint-xs) {
        display: inline !important;
    }
}

span.Disabled {
    pointer-events: none;
    filter: grayscale(0.75);
    opacity: 0.6;
}
