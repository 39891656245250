@import '../../variables/variables';

.EditStockContainer {
  margin-bottom: 130px;
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.Orange {
      font-size: 1.18rem;
      width: 48%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.Button.Basic.Modal,
.Button.Orange.Modal {
  font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  font-size: 1.18rem;
  width: 48%;

  @media (min-width: $breakpoint-xs) {
    font-size: 1.28571429rem;
    width: 100%;
  }
}

.Button.Basic.Modal {
  color: #F2711C !important;
}

.Modal.Description {
  font-size: 18px;
  padding: 12px 22px;
}

.Modal.Container {
  padding: 0;
}

.IngredientSearch {
  text-align: center;
}

.IngredientSearch input {
  width: 200px !important;
  font-size: 15px !important;

  @media (min-width: $breakpoint-xs) {
    width: 350px !important;
    font-size: 17px !important;
  }
  
  @media (min-width: $breakpoint-s) {
    width: 500px !important;
  }
  
  color: #FF795E !important;

  &::placeholder {
    color: #FF795E !important;
  }
}

.Items {
  display: inline-block;
  margin-top: 30px;
  font-size: 15px !important;
  color: #BDBDBD !important;

  @media (min-width: $breakpoint-s) {
    font-size: 20px !important;
  }
}

.FixedWidth {
  width: 45%;

  @media (min-width: $breakpoint-xs) {
    width: 35%;
  }

  @media (min-width: $breakpoint-s) {
    width: 30%;
  }
}