.AccordionContainer {
  .AccordionTitle {
    display: flex;
    width: 82%;
    align-items: center;
    padding-left: 6px;

    h3 {
      font-weight: 300;
      font-size: 1.1rem;
      color: #006666;
    }

    i.Icon {
      color: #5FF0A6;
      width: 10%;
      text-align: right;
    }
  }

  p.Accordion.Item {
    font-size: 1.1rem;
    text-align: left;
    padding-left: 6px;
    font-weight: 300;
  }
}

.CustomAccordionContainer {
  width: 90%;
  margin: 0 auto 12px;
  
  .AccordionTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-weight: 300;
      font-size: 30px;
      color: #BBBBBB;
    }

    i.Icon {
      color: #5FF0A6;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }

  div.Accordion.Item {
    padding: 20px 30px 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;

    h3 {
      color: #333333;
      font-size: 22px ;
    }

    p {
      color: #5A5A5A;
      font-size: 16px;
    }

    span.Amount {
      color: #000000;
      font-size: 20px;
      width: 10%;
      text-align: right;
    }
  }
}