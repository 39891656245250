#root {
  width: 100%;
  height: 100%;
}

.main {
  padding: calc(#{$topbar-height-mobile} + 20px) 0 30px 0;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 700px;

  &.selected-location {
    padding: 14px !important;
  }

  &.selected-location.costing-calculator {
    max-width: 1164px;
  }

  &.selected-location.order-distributors {
    padding-bottom: 84px !important;
  }

  &.order-form {
    max-width: 760px;
    margin-left: 20px;
    margin-right: 20px;
  }

  &.login-area {
    padding-top: 110px;
  }

  &.login-area:before {
    background-image: url('../../assets/images/splash_image.svg');
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.3;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 80%;
  }

  @media (max-width: $breakpoint-xs) {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  @media (min-width: $breakpoint-s) {
    padding-top: calc(#{$topbar-height-dt} + 20px) !important;
    margin-left: auto !important;
    margin-right: auto !important;

    &.login-area:before {
      background-size: auto 50%;
    }

    &.selected-location.order-distributors {
      padding-bottom: 104px !important;
    }
  }
}

.btn-container {
  &.top {
    top: calc(#{$topbar-height-mobile} - 19px);
    z-index: 4;
    left: 50%;
    transform: translateX(-50%);

    &.search {
      min-width: 250px;

      .ui.search > .ui.input > input {
        width: 250px;
      }
    }

    @media (min-width: $breakpoint-s) {
      top: calc(#{$topbar-height-dt} - 19px);

      &.search {
        top: calc(#{$topbar-height-dt} - 20px);
      }
    }

    .ui.basic.button {
      background-color: #fff !important;
    }
  }

  &.fixed {
    z-index: 4;
  }

  &.floating {
    background: #fff;
    box-shadow: 0px -5px 10px #ccc;
    padding: 30px 25px;

    @media (max-width: $breakpoint-s) {
      padding: 20px 25px;
    }
  }
}

@media (max-width: $breakpoint-s) {
  .clndr-calendars-segment.clndr-floating {
    width: 100% !important;
  }
  .clndr-calendars-wrapper {
    display: block !important;
  }
}

.portal-popup {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  max-width: 350px !important;
  width: 350px !important;
  margin-left: -175px !important;
}

.StripeElement {
  padding: 0.9em 1em 1em;
  background-color: #fafafa;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}
