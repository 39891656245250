@import '../../variables/variables';

.StockItemDetails {
  margin-top: 20px !important;
  color: #333333;
}

.Title {
  border-bottom: 1px #333333 solid;
  padding: 0 !important;
  margin: 1rem;
  font-size: 17px;
}

.Tag,
.DistributorsTitle {
  color: #828282;
  font-size: 15px;
}

.DistributorsDescription {
  font-size: 16px;

  div {
    padding-left: 26px !important;
  }
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.Orange {
      font-size: 1.18rem;
      width: 48%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}

.Button.Basic.Modal,
.Button.Orange.Modal {
  font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  font-size: 1.18rem;
  width: 48%;

  @media (min-width: $breakpoint-xs) {
    font-size: 1.28571429rem;
    width: 100%;
  }
}

.Button.Basic.Modal {
  color: #F2711C !important;
}

.Modal.Description {
  font-size: 18px;
  padding: 12px 22px;
}

.Modal.Container {
  padding: 0;
}