@import '../../variables/colors';
@import '../../variables/variables';

.RecipeList {
  margin: 0 auto 90px;
  display: flex;
  flex-direction: column;
  max-width: 480px;
}

.Filters {
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}

.ItemCount {
  color: $grey-light;
  margin-bottom: 20px;
}

.Checkbox {
  label {
    color: $grey-light !important;
  }
}

.AddMenuContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px !important;
}

.Menu {
  justify-content: space-between;
  overflow-x: overlay;
}

.Menu::-webkit-scrollbar {
  display: none;
}

.Menu::after {
  display: none !important;
}

.MenuItem {
  border-radius: 20px !important;
  padding: 10px 6px !important;
  margin-right: 0;

  @media (min-width: $breakpoint-xxs) {
    padding: 10px !important;
  }

  @media (min-width: $breakpoint-xs) {
    padding: 10px 20px !important;
    margin-right: 20px;
  }
}
.MenuItem:last-of-type {
  margin-right: 0px;
}

.NoPOSMessage {
  display: block;
  padding: 16px 24px;
  margin: 0 0 16px;
  color: #FF664A;
  background-color: #FF664A1A;
  border: 1px solid #FF664A;
  font-size: 12px;
  border-radius: 5px;
}

.ArchivedLocationsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  div {
    width: 100%;
  }

  @media (min-width: $breakpoint-xs) {
    flex-direction: row;
  }
}

.GridRow {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapperCentered {
    max-width: 700px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .Button.Green {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 1.18rem;
      width: 55%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}