@import '../../variables/variables';

.TextInput {
  position: absolute;
  top: 9px;
  right: 12px;
}

.Input.Costing.Calculator {
  width: 50% !important;

  input {
    border-radius: 80px !important;
    padding: 0 0 0 10px !important;
  }
}

.Input.Stock {
  display: inline-block;
  width: 100% !important;
}

div[class*=distributor-inputs] div[class*=error] {
  .TextInput {
    top: 60px !important;
    color: #DE6360 !important;

    @media (min-width: $breakpoint-xs) {
      top: 46px !important;
    }
  }
}

.FirstTextInputStock {
  position: absolute;
  top: 32px;
  right: 42px;

  @media (min-width: $breakpoint-xs) {
    right: 48px;
  }
}

.TextInputStock {
  position: absolute;
  top: 32px;
  right: 40px;

  @media (min-width: $breakpoint-xs) {
   right: 48px;
  }
}

div.Input.FullHeight input {
  height: 100% !important;
  margin-top: 10px !important;

  @media (min-width: $breakpoint-s) {
    margin-top: 0 !important;
  }
}

div.Input.ShoppingList input {
  width: 100%;
  border-radius: 30px;
}