.topbar {
  height: $topbar-height-mobile;
  min-height: 1vh;
  top: 0;
  left: 0;
  background-color: $green;
  z-index: 4;
  color: $white;

  .back-btn {
    position: absolute;
    left: 25px;
    top: 0;
    height: 100%;
    padding: 22px 0;
    box-sizing: border-box;
    cursor: pointer;

    img {
      height: 100%;
    }
  }
}

.page-title {
  box-sizing: border-box;
  padding: 25px 0;
  text-align: center;
  flex-direction: column;

  h1 {
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }
}

.logo {
  width: 152.37px;
  height: 47px;
  align-self: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.hamburger {
  width: 22px;
  height: 100%;
  padding: 25px 0;
  position: absolute;
  top: 0;
  right: 25px;
  flex-flow: column;
  justify-content: space-around;
  box-sizing: border-box;
  cursor: pointer;

  div {
    width: 100%;
    height: 3px;
    background-color: $white;
    border-radius: 2px;
  }
}

// DT header styles
@media (min-width: $breakpoint-s) {
  .topbar {
    height: $topbar-height-dt;

    .back-btn {
      padding: 30px 0;
    }

    .page-title {
      padding: 28px 38px;

      h1 {
        font-size: 30px;
      }
    }

    .logo {
      width: 250px;
      height: 80px;
    }

    .hamburger {
      padding: 35px 0;
      width: 27px;
    }

    .padding-inherit {
      padding: inherit;
    }
  }
}

// Generic nav-item styles
.nav-item {
  a,
  span {
    text-decoration: none;
    color: $black;
    display: flex;
    white-space: nowrap;

    &:hover {
      color: $grey;

      &:before,
      img {
        opacity: 0.5;
      }
    }
  }

  a:before,
  span:before {
    content: '';
    flex-basis: 30%;
    height: 26px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: $breakpoint-xs) {
      height: 30px;
    }
  }
}

.sidebar {
  position: fixed;
  width: 320px;
  max-width: 70%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #fff;
  padding-bottom: 20px;
  transition: transform 0.3s ease-out;
  text-align: left;
  opacity: 1;
  display: flex;
  flex-direction: column;

  &.open {
    transform: translateX(0);
  }

  &.closed {
    transform: translateX(+100%);
    opacity: 0;
  }

  .client-details {
    height: 100px;
    border-bottom: 1px solid $black;
    box-sizing: border-box;
    padding: 20px 15px;
    display: flex;
    align-items: center;

    .logo-container {
      flex-basis: 30%;
    }

    .name {
      flex-basis: 70%;
      font-size: 18px;

      @media (min-width: $breakpoint-xs) {
        font-size: 22px;
      }
    }
  }

  .logo.client {
    border: 1px solid #eee;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding: 6px;
  }

  .btm-container {
    margin-top: auto;
    padding: 15px 0;

    .branding {
      border-bottom: 1px solid $black;
      padding: 0 15px 5px;
      display: flex;

      .logo {
        height: 25px;
      }

      span {
        font-size: 14px;
        width: 80%;

        @media (min-width: $breakpoint-xs) {
          font-size: 16px;
        }
      }
    }

    .links {
      padding: 10px 15px 0;
      display: flex;
      justify-content: space-between;
    }

    .settings {
      img {
        width: 26px;
        height: 26px;
      }

      span {
        display: inline-block;
        margin-left: 5px;
      }
    }

    .logout {
      span:before {
        content: '';
        background-image: url('../../assets/images/navigation/logout.svg');
        width: 20px;
        height: 25px;
        margin-right: 8px;
      }
    }
  }
}

// Rendered only in the sidebar
nav {
  ul {
    margin: 0;
    padding: 15px;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    height: 100%;
    box-sizing: border-box;
    font-size: 18px;

    @media (min-width: $breakpoint-xs) {
      font-size: 22px;
    }
  }

  .nav-item {
    margin: 15px 0;
    box-sizing: border-box;
    width: 100%;

    &.impact a:before {
      background-image: url('../../assets/images/navigation/impact.svg');
    }
    &.notifications a:before {
      background-image: url('../../assets/images/navigation/notifications.svg');
    }
    &.stock a:before {
      background-image: url('../../assets/images/navigation/stock.svg');
    }
    &.orders a:before {
      height: 41px;
      margin-bottom: -15px;
      background-image: url('../../assets/images/navigation/orders.svg');

      @media (min-width: $breakpoint-xs) {
        height: 45px;
      }
    }
    &.profile a:before {
      background-image: url('../../assets/images/navigation/profile.svg');
    }
    &.menu a:before {
      background-image: url('../../assets/images/navigation/menu.svg');
    }
    &.distributors a:before {
      background-image: url('../../assets/images/navigation/distributors.svg');
    }
    &.locations a:before {
      background-image: url('../../assets/images/navigation/locations.svg');
    }
    &.metrics a:before {
      height: 35px;
      background-image: url('../../assets/images/navigation/metrics.svg');
    }
  }
}

.padding-inherit {
  padding: inherit;
}
